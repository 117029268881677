var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form",
    {
      staticClass: "mt-4",
      attrs: {
        labelCol: { span: 4 },
        wrapperCol: { span: 6 },
        form: _vm.form,
        "label-align": "left"
      },
      nativeOn: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submitForm.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.assetNumber.label) } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.assetNumber.decorator,
                expression: "formRules.assetNumber.decorator"
              }
            ],
            attrs: {
              disabled: "",
              name: _vm.formRules.assetNumber.name,
              placeholder: _vm.$t(_vm.formRules.assetNumber.placeholder)
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.assetType.label) } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.assetType.decorator,
                expression: "formRules.assetType.decorator"
              }
            ],
            attrs: { disabled: "", name: _vm.formRules.assetType.name }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.book.label) } },
        [
          _c(
            "a-select",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: _vm.formRules.book.decorator,
                  expression: "formRules.book.decorator"
                }
              ],
              attrs: {
                disabled: _vm.disabledBook,
                name: _vm.formRules.book.name,
                placeholder: _vm.$t(_vm.formRules.book.placeholder),
                loading: _vm.loadingListAssetBook
              },
              on: { change: _vm.changeBook }
            },
            _vm._l(_vm.dataListBook, function(data, index) {
              return _c(
                "a-select-option",
                { key: index, attrs: { value: data.id } },
                [
                  _c(
                    "a-tooltip",
                    [
                      _c("template", { slot: "title" }, [
                        _vm._v(" " + _vm._s(data.name) + " ")
                      ]),
                      _vm._v(" " + _vm._s(data.name) + " ")
                    ],
                    2
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.unit.label) } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.unit.decorator,
                expression: "formRules.unit.decorator"
              }
            ],
            attrs: {
              disabled: "",
              name: _vm.formRules.unit.name,
              placeholder: _vm.$t(_vm.formRules.unit.placeholder)
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.currentCost.label) } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.currentCost.decorator,
                expression: "formRules.currentCost.decorator"
              }
            ],
            staticClass: "w-100",
            attrs: {
              disabled: _vm.idReferenceNumber ? true : false,
              name: _vm.formRules.currentCost.name,
              placeholder: _vm.$t(_vm.formRules.currentCost.placeholder)
            },
            on: {
              blur: function($event) {
                return _vm.onBlur($event, "currentCost")
              },
              focus: function($event) {
                return _vm.onFocus($event, "currentCost")
              }
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.originalCost.label) } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.originalCost.decorator,
                expression: "formRules.originalCost.decorator"
              }
            ],
            staticClass: "w-100",
            attrs: {
              disabled: "",
              name: _vm.formRules.originalCost.name,
              placeholder: _vm.$t(_vm.formRules.originalCost.placeholder)
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.method.label) } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.method.decorator,
                expression: "formRules.method.decorator"
              }
            ],
            attrs: {
              disabled: "",
              name: _vm.formRules.method.name,
              placeholder: _vm.$t(_vm.formRules.method.placeholder)
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.lifeMonths.label) } },
        [
          _c("a-input-number", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.lifeMonths.decorator,
                expression: "formRules.lifeMonths.decorator"
              }
            ],
            staticClass: "w-100",
            attrs: {
              disabled: _vm.idReferenceNumber ? true : false,
              name: _vm.formRules.lifeMonths.name,
              placeholder: _vm.$t(_vm.formRules.lifeMonths.placeholder),
              parser: _vm.formatNumericOnly
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.mode === _vm.Mode.EDIT,
              expression: "mode === Mode.EDIT"
            }
          ],
          attrs: { label: _vm.$t(_vm.formRules.receiveDate.label) }
        },
        [
          _c("a-date-picker", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.receiveDate.decorator,
                expression: "formRules.receiveDate.decorator"
              }
            ],
            staticClass: "w-100",
            attrs: {
              disabled: "",
              name: _vm.formRules.receiveDate.name,
              placeholder: _vm.$t(_vm.formRules.receiveDate.placeholder),
              format: _vm.DEFAULT_DATE_FORMAT
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.acquisitionDate.label) } },
        [
          _c("a-date-picker", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.acquisitionDate.decorator,
                expression: "formRules.acquisitionDate.decorator"
              }
            ],
            staticClass: "w-100",
            attrs: {
              disabled: _vm.idReferenceNumber ? true : false,
              name: _vm.formRules.acquisitionDate.name,
              placeholder: _vm.$t(_vm.formRules.acquisitionDate.placeholder),
              format: _vm.DEFAULT_DATE_FORMAT,
              "disabled-date": _vm.isLessThanReceiveDate
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.depreciate.label) } },
        [
          _c("a-checkbox", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.depreciate.decorator,
                expression: "formRules.depreciate.decorator"
              }
            ],
            attrs: { disabled: true }
          })
        ],
        1
      ),
      _c(
        "a-button",
        {
          staticStyle: { "margin-right": "0.5rem" },
          attrs: { type: "danger" },
          on: { click: _vm.handleCancel }
        },
        [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
      ),
      _vm.$can("update", "asset")
        ? _c(
            "a-button",
            {
              key: "submit",
              attrs: {
                type: "primary",
                loading: _vm.isFormSubmitted,
                "html-type": "submit",
                disabled: _vm.disabledSave
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }